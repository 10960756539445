import { getCookie, setCookie } from './cookies';
import BrockmanTabs from './tabs';

export default class BrockmanComments {
  constructor(args) {
    this.id = args.id;
    this.fragment = args.fragment || false;
    this.defaultOrder = args.defaultOrder || 'asc';
    this.previewEndpoint = args.previewEndpoint || null;
    this.orderCookieName = args.orderCookieName || 'brockman_comments_order';
    this.postCount = parseInt(args.postCount, 10) || 0;

    this.ctrlShortcutKeys = {
      b: 'bold',
      i: 'italic',
      k: 'link',
    };

    this.ctrlShiftShortcutKeys = {
      8: 'list',
      9: 'quote',
    };

    // Localisation
    this.loc = {
      commentError: `Sorry, but an error occurred. Please try again.`,
      emptyCommentError: `You need to write a response to submit a comment.`,
      emptyReportReason: `Please select a reason from the list for your report to be processed.`,
      errorMessage: `Comments failed to load.`,
      genericError: `Sorry, something went wrong.`,
      labelNoResponse: `Write a response to this article`,
      like: `Like`,
      loading: `Loading`,
      loggedOutLike: `Sign in to like this comment`,
      postComment: `Post comment`,
      reportSubmitted: `Thank you for submitting a report about this comment. Your report will be added to a queue for our moderation team to review.`,
      submittedComment: `Comment submitted. Thanks for contributing!`,
      unavailableLike: `You are not able to like this comment`,
      writeComment: [`Write a spectacular comment`],
    };

    // Update localisation
    if (args.loc) {
      Object.keys(this.loc).forEach((key) => {
        if (args.loc[key]) this.loc[key] = args.loc[key];
      });
    }

    // Initialise
    this.pending = false;
    this.commentsFetched = false;
    this.inlinePostForm = null;
    this.container = null;
  }

  run() {
    this.container = document.querySelector('#comments .container');
    this.anchor = this.getAnchor();
    this.fetchInitial();
  }

  getAnchor() {
    const [anchor] = window.location.hash.split('-');
    if (anchor === '#comments') return window.location.hash.slice(1);
    return null;
  }

  getOrderCookie() {
    return getCookie(this.orderCookieName) || this.defaultOrder;
  }

  setOrderCookie(order) {
    setCookie(this.orderCookieName, order, 365);
  }

  sendToPiwik(action) {
    window._paq = window._paq || [];
    window._paq.push([
      'trackEvent',
      'comment', // Category
      action, // Action
    ]);
  }

  sendToPermutive(value) {
    if (window.permutive) {
      window.permutive.track('UserEngagement', {
        action: 'Comment',
        value,
      });
    }
  }

  updateLocalisation(target) {
    const submitButtons = target.querySelectorAll('.submit');
    if (submitButtons) {
      submitButtons.forEach((button) => {
        button.value = this.loc.postComment;
      });
    }

    const textareas = this.container.querySelectorAll('textarea[name="content"]');
    if (textareas) {
      textareas.forEach((textarea) => {
        this.updateWriteCommentPlaceholderText(textarea);
      });
    }
  }

  updateWriteCommentPlaceholderText(textarea) {
    if (textarea) textarea.placeholder = this.getWriteCommentPlaceholderText();
  }

  getWriteCommentPlaceholderText() {
    const { writeComment } = this.loc;
    return writeComment[Math.floor(Math.random() * writeComment.length)];
  }

  registerFilters() {
    const sort = this.container.querySelector('.sort__order');
    sort.addEventListener('change', (e) => this.changeOrder(e));
    sort.value = this.container.dataset.order;
  }

  registerRootPostForm() {
    const rootPostForm = this.container.querySelector('.root_post_form');
    if (!rootPostForm) return;

    rootPostForm.querySelector('.submit').addEventListener('click', this.clickSubmitComment.bind(this));
    this.registerPostForm(rootPostForm);
  }

  registerInlinePostForm() {
    this.inlinePostForm = this.container.querySelector('.inline_post_form');
    if (!this.inlinePostForm) return;

    this.inlinePostForm.querySelector('.submit').addEventListener('click', this.clickSubmitReply.bind(this));
    this.inlinePostForm.querySelector('.cancel').addEventListener('click', this.clickCancelReplying.bind(this));
    this.registerPostForm(this.inlinePostForm);

    this.closeInlinePostForm();
  }

  registerPostForm(container) {
    const tabs = new BrockmanTabs({ container });
    tabs.run();
    tabs.registerCallback((tab) => {
      if (tab.classList.contains('preview')) this.getPreview(container);
    });

    const buttons = container.querySelectorAll('.action_button');
    const textarea = container.querySelector('textarea[name="content"]');

    buttons.forEach((button) => {
      const { action } = button.dataset;
      button.addEventListener('click', () => this.insertFormatting(textarea, action));
    });

    textarea.addEventListener('keydown', (e) => this.checkShortcutKeys(textarea, e));
  }

  checkShortcutKeys(textarea, e) {
    const ctrl = e.ctrlKey || e.metaKey;
    if (!ctrl) return;

    const { shiftKey } = e;
    const key = e.key.toLowerCase();

    const action = shiftKey ? this.ctrlShiftShortcutKeys[key] : this.ctrlShortcutKeys[key];
    if (action) {
      e.preventDefault();
      this.insertFormatting(textarea, action);
    }
  }

  insertFormatting(textarea, action) {
    switch (action) {
      case 'bold':
        this.wrapText(textarea, '**', '**');
        break;

      case 'italic':
        this.wrapText(textarea, '*', '*');
        break;

      case 'spoiler':
        this.wrapText(textarea, '||', '||');
        break;

      case 'link':
        this.wrapLink(textarea);
        break;

      case 'quote':
        this.insertTextAtStartOfLine(textarea, '> ');
        break;

      case 'list':
        this.insertTextAtStartOfLine(textarea, '- ');
        break;

      default:
        break;
    }
  }

  insertTextAtStartOfLine(textarea, insert) {
    const { selectionStart, selectionEnd, value } = textarea;

    // Get the nearest (previous) newline to the selection start
    const newlinePos = value.slice(0, selectionStart).lastIndexOf('\n') + 1;

    // Insert new text there
    textarea.focus();
    textarea.setSelectionRange(newlinePos, newlinePos);
    // execCommand used here to preserve undo history (although deprecated it is
    // fully supported still and there is currently no replacement)
    document.execCommand('insertText', false, insert);

    // Retain previous selection
    const offset = insert.length;
    textarea.setSelectionRange(selectionStart + offset, selectionEnd + offset);
  }

  wrapText(textarea, start, end) {
    const { selectionStart, selectionEnd } = textarea;
    const insertAfter = selectionStart !== selectionEnd;
    const offset = insertAfter ? 0 : end.length * -1;
    this.insertWrappedText(textarea, start, end, offset, offset);
  }

  wrapLink(textarea) {
    const { selectionStart, selectionEnd } = textarea;

    // If something is selected: wrap existing text as label and select link URL
    let end = '](example.com)';
    let selection = 'example.com';

    if (selectionStart === selectionEnd) {
      // If nothing is selected: insert without wrapping and select link label
      end = 'link](example.com)';
      selection = 'link';
    }

    const offsetStart = (end.length - end.indexOf(selection)) * -1;
    const offsetEnd = offsetStart + selection.length;

    this.insertWrappedText(textarea, '[', end, offsetStart, offsetEnd);
  }

  insertWrappedText(textarea, start, end, offsetStart = 0, offsetEnd = 0) {
    const { selectionStart, selectionEnd, value } = textarea;
    const selection = value.substr(selectionStart, selectionEnd - selectionStart);

    textarea.focus();
    // execCommand used here to preserve undo history
    document.execCommand('insertText', false, start + selection + end);

    const pos = selectionStart + (start + selection + end).length;
    textarea.setSelectionRange(pos + offsetStart, pos + offsetEnd);
  }

  getPreview(container) {
    if (!this.previewEndpoint) return;

    const form = container.querySelector('form');
    const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;
    const preview = container.querySelector('.preview');
    const compose = container.querySelector('.compose');

    preview.innerHTML = '<span class="spinner inverted large"></span>';
    preview.classList.add('loading');
    preview.style.height = compose.style.height;

    fetch(new Request(this.previewEndpoint), {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrfToken,
      },
      body: new FormData(form),
    })
      .then((response) => this.checkPreviewStatus(response))
      .then((response) => response.text())
      .then((markup) => {
        preview.innerHTML = markup;
        preview.classList.remove('loading');
      })
      .catch(() => {
        preview.innerHTML = `<span class="alert error">${this.loc.genericError}</span>`;
        preview.classList.remove('loading');
      });
  }

  checkPreviewStatus(response) {
    if (response.ok && response.status === 200) return response;
    throw new Error('Response not OK');
  }

  registerCommentButtons(target) {
    if (!target) return;

    this.registerButtons('.submit', 'click', this.clickSubmitComment, target);
    this.registerButtons('.edit', 'click', this.clickEditComment, target);
    this.registerButtons('.remove', 'click', this.clickRemoveComment, target);
    this.registerButtons('.reply', 'click', this.clickReply, target);
    this.registerButtons('.like', 'click', this.clickLike, target);
    this.registerButtons('.pin', 'click', this.clickPin, target);
    this.registerButtons('.report', 'click', this.clickReport, target);
    this.registerButtons('[data-action="show"]', 'click', this.clickShowRepliesToPost, target);
    this.registerButtons('[data-action="hide"]', 'click', this.clickHideRepliesToPost, target);
  }

  registerButtons(selector, event, listener, target) {
    const buttons = target.querySelectorAll(selector);
    if (!buttons) return;

    buttons.forEach((button) => {
      button.addEventListener(event, listener.bind(this));
    });
  }

  clickSubmitComment(e) {
    this.handlePostingComment(e, false);
  }

  clickSubmitEditedComment(e) {
    this.handlePostingComment(e, true);
  }

  clickSubmitReply(e) {
    this.handlePostingComment(e, false);
  }

  clickCancelEditing(e) {
    this.closeEditing(e.target.closest('.post'));
  }

  clickReply(e) {
    this.closeInlinePostForm();

    const post = e.target.closest('.post');
    post.dataset.action = 'replying';

    // Update the endpoint to post the reply to
    const endpoint = this.getReplyEndpoint(post);
    if (!endpoint) return;
    this.inlinePostForm.querySelector('form').action = endpoint;

    // Move inline post form
    const actions = e.target.closest('.actions');
    actions.before(this.inlinePostForm);
    this.inlinePostForm.style.display = null;

    // Add @username text
    const username = post.querySelector('.username .name').innerText;
    const textarea = this.inlinePostForm.querySelector('textarea[name="content"]');
    const value = `@${username} `;
    textarea.value = value;
    textarea.dataset.defaultValue = value.trim();
    textarea.focus();
  }

  getReplyEndpoint(post) {
    // Construct the endpoint using the hashids for the thread and parent post
    const threadHashid = post.closest('.thread').dataset.hashid;
    const postHashid = post.dataset.hashid;
    if (postHashid && threadHashid)
      return `/community/thread/${threadHashid}/${postHashid}/reply`;
    return null;
  }

  clickCancelReplying() {
    this.closeInlinePostForm();
  }

  clickConfirmRemoveComment(e) {
    e.preventDefault();
    const post = e.target.closest('.post');
    const form = post.querySelector('.editing form');
    const endpoint = post.querySelector('.remove').getAttribute('href');
    this.request('DELETE', endpoint, post, form, true);
    post.dataset.action = false;
    this.closeEditing(post);
  }

  // Like a comment
  clickLike(e) {
    e.preventDefault();
    const likeForm = e.target.closest('.like_form');
    const post = e.target.closest('.post');
    let endpoint;

    if (likeForm) {
      if (e.target.dataset.liked === 'true') {
        endpoint = this.getButtonEndpoint(post, 'unlike');
      } else {
        endpoint = this.getButtonEndpoint(post, 'like');
      }
      this.jsonRequest(endpoint, likeForm, e.target, post);
    } else if (e.target.classList.contains('unavailable')) {
      this.renderActionAlert(post.querySelector('.what'), this.loc.unavailableLike, 'info');
    } else {
      const { id } = post;
      const next = new URL(window.location.href);
      if (id) next.hash = `#${id}`;
      const link = `/community/auth/start?next=${encodeURIComponent(next)}`;
      const markup = `<a href="${link}">${this.loc.loggedOutLike}</a>.`
      this.renderActionAlert(post.querySelector('.what'), markup, 'info');
    }
  }

  // Pin a comment
  clickPin(e) {
    e.preventDefault();
    const post = e.target.closest('.post');
    const pinning = post.querySelector('.pinning');
    pinning.classList.remove('hidden');
    pinning.querySelector('.submit_action').addEventListener('click', this.clickSubmitPin.bind(this));
    pinning.querySelector('.cancel_action').addEventListener('click', this.clickCancelPin.bind(this));
  }

  clickSubmitPin(e) {
    const post = e.target.closest('.post');
    const form = post.querySelector('.pin_form');
    const endpoint = form.getAttribute('action');

    this.jsonRequest(endpoint, form, e.target, post);
  }

  clickCancelPin(e) {
    const post = e.target.closest('.post');
    const pinning = post.querySelector('.pinning');
    pinning.classList.add('hidden');
  }

  // Report a comment
  clickReport(e) {
    e.preventDefault();
    this.removeAllAlerts();
    const post = e.target.closest('.post');
    const reporting = this.container.querySelector('.reporting');

    document.body.style.overflow = 'hidden';
    reporting.style.display = 'flex';
    reporting.querySelector('.submit_action').addEventListener('click', this.clickSubmitReport.bind(this, post));
    reporting.querySelector('.cancel_action').addEventListener('click', this.clickCancelReport.bind(this, reporting));
  }

  clickSubmitReport(post, e) {
    e.preventDefault();
    const reportPost = post;
    const thread = reportPost.closest('.thread');
    const form = this.container.querySelector('.reporting_form');

    if (form.checkValidity() && reportPost && thread) {
      const endpoint = `/community/thread/${thread.dataset.hashid}/${reportPost.dataset.hashid}/report`;
      this.jsonRequest(endpoint, form, e.target, reportPost);
    } else if (!form.checkValidity()) {
      this.removeAllAlerts();
      this.renderActionAlert(form, this.loc.emptyReportReason, 'error');
    }
  }

  getButtonEndpoint(reportPost, url) {
    // Construct the endpoint using the hashids for the thread and parent post
    const post = reportPost;
    const threadHashid = post.closest('.thread').dataset.hashid;
    const postHashid = post.dataset.hashid;
    if (postHashid && threadHashid) return `/community/thread/${threadHashid}/${postHashid}/${url}`;
    return null;
  }

  clickCancelReport(reporting, e) {
    const form = reporting.querySelector('.reporting_form');
    form.reset();
    reporting.style.display = 'none';
    document.body.style.overflow = 'auto';
    this.refreshCommentEvents();
  }

  jsonRequest(endpoint, form, target, post) {
    // Simple request for comment actions which collects json data for likes and reporting
    const requestPost = post;
    const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;
    fetch(new Request(endpoint), {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrfToken,
      },
      body: new FormData(form),
    })
      .then((response) => response.json())
      .then((json) => this.collectJsonData(json, target, requestPost))
      .catch((error) => this.renderActionAlert(requestPost.querySelector('.what'), error));
  }

  collectJsonData(json, target, requestPost) {
    const likeCount = parseInt(json.like_count, 10);
    if (likeCount >= 0) {
      requestPost.dataset.likeCount = likeCount;
      if (target.dataset.liked === 'true') {
        target.dataset.liked = false;
      } else {
        this.sendToPiwik('comment liked');
        this.sendToPermutive('Liked');
        target.dataset.liked = true;
      }
      if (likeCount === 0) {
        target.innerHTML = this.loc.like;
      } else {
        target.innerHTML = `${this.loc.like} (${likeCount})`;
      }
    }

    if (json.reported) {
      const form = this.container.querySelector('.reporting_form');
      const reportButton = requestPost.querySelector('.report');
      const reporting = form.closest('.reporting');

      this.renderActionAlert(requestPost.querySelector('.what'), this.loc.reportSubmitted, 'info');
      reporting.style.display = 'none';
      reportButton.disabled = true;
      reportButton.innerHTML = 'Reported';
      requestPost.dataset.reported = true;
      form.reset();
      document.body.style.overflow = 'auto';
      this.refreshReportingEvents();
    }

    if (target.parentNode.parentNode.classList.contains('pinning')) {
      const pinning = requestPost.querySelector('.pinning');
      pinning.style.display = 'none';
      window.location.reload();
    }
  }

  refreshReportingEvents() {
    const oldReport = this.container.querySelector('.reporting_form');
    const newReport = oldReport.cloneNode(true);
    oldReport.parentNode.replaceChild(newReport, oldReport);
    this.registerCommentButtons(newReport);
  }

  renderActionAlert(after, text, classList = 'error') {
    // Replace post alerts without removing this.container alert
    this.posts = this.container.querySelector('.posts');
    this.removeAllAlerts(this.posts);

    const alert = document.createElement('div');
    alert.classList = `alert temp ${classList}`;
    alert.innerHTML = `<ul><li>${text}</li></ul>`;
    this.insertAfter(alert, after);
  }

  changeOrder(e) {
    const order = e.target.value;
    this.container.dataset.order = order;
    this.setOrderCookie(order);
    this.refreshPostOrder();
  }

  refreshPostOrder() {
    switch (this.container.dataset.order) {
      case 'popular':
        this.reorderPosts(this.sortPostsByLikes, false);
        break;

      case 'desc':
        this.reorderPosts(this.sortPostsByDate, false);
        break;

      case 'asc':
      default:
        this.reorderPosts(this.sortPostsByDate, true);
        break;
    }

    this.prependPinnedPost();
  }

  reorderPosts(fn, reverse, includeReplies = false) {
    const selectors = ['.posts'];
    if (includeReplies) selectors.push('.post__children');
    const containers = this.container.querySelectorAll(selectors.join(', '));
    containers.forEach((container) => {
      const posts = container.querySelectorAll(':scope > .post__wrapper > .post');
      const sorted = reverse ? fn(posts).reverse() : fn(posts);
      sorted.forEach((post) => container.append(post.parentElement));
    });
  }

  prependPinnedPost() {
    const pinned = this.container.querySelector('.post[data-pinned="true"]');
    if (!pinned) return;
    const container = this.container.querySelector('.posts');
    container.prepend(pinned.parentElement);
  }

  sortPostsByDate(posts) {
    return [...posts].sort((a, b) => parseFloat(b.dataset.date) - parseFloat(a.dataset.date));
  }

  sortPostsByLikes(posts) {
    return [...posts].sort((a, b) => {
      const likes = (el) => parseInt(el.dataset.likeCount, 10) || 0;
      // If like counts are the same, pick the older comment to display first
      return likes(b) - likes(a) || parseFloat(a.dataset.date) - parseFloat(b.dataset.date);
    });
  }

  setButtonActive(target) {
    target.parentNode.querySelectorAll('button').forEach((button) => button.classList.remove('active'));
    target.classList.add('active');
    target.blur();
  }

  clickShowRepliesToPost(e) {
    const post = e.target.closest('.post');
    post.dataset.collapsed = false;
    e.target.blur();
    this.scrollToElement(post);
  }

  clickHideRepliesToPost(e) {
    e.target.closest('.post').dataset.collapsed = true;
    e.target.blur();
  }

  clickEditComment(e) {
    this.fetchModifyComment(e, false);
  }

  clickRemoveComment(e) {
    this.fetchModifyComment(e, true);
  }

  fetchModifyComment(e, isRemoving) {
    e.preventDefault();
    if (this.pending) return;

    const endpoint = e.target.getAttribute('href');
    const post = e.target.closest('.post');

    const request = new Request(endpoint);
    this.setPending(true);
    fetch(request)
      .then((response) => this.checkStatus(response))
      .then((response) => this.renderModifyComment(response, post, isRemoving))
      .catch((error) => this.addAlertAfterPost(post, error));
  }

  renderModifyComment(response, post, isRemoving) {
    response.text().then((comment) => {
      this.closeAllEditing();

      const editing = document.createElement('div');
      editing.innerHTML = comment;
      editing.classList.add('editing');

      this.insertAfter(editing, post.querySelector('.what'));

      if (isRemoving === true) {
        post.dataset.action = 'removing';
        editing.querySelector('.confirm').addEventListener('click', this.clickConfirmRemoveComment.bind(this));
      } else {
        post.dataset.action = 'editing';
        editing.querySelector('.submit').addEventListener('click', this.clickSubmitEditedComment.bind(this));

        // Move caret to end of text
        const textarea = editing.querySelector('textarea[name="content"]');
        textarea.focus();
        const { value } = textarea;
        textarea.value = '';
        textarea.dataset.defaultValue = '';
        textarea.value = value;

        this.registerPostForm(editing);
      }

      editing.querySelector('.cancel').addEventListener('click', this.clickCancelEditing.bind(this));
    });
  }

  closeAllEditing() {
    const allEditing = this.container.querySelectorAll('.editing');
    if (!allEditing) return;
    allEditing.forEach((editing) => {
      const post = editing.closest('.post');
      post.dataset.action = false;
      editing.remove();
    });
  }

  closeEditing(post) {
    const editing = post.querySelector(':scope > .editing');
    if (editing) editing.remove();
    post.dataset.action = false;
  }

  closeInlinePostForm() {
    this.inlinePostForm.style.display = 'none';
    const post = this.inlinePostForm.closest('.post');
    if (post) post.dataset.action = false;
    this.removeAllAlerts();
  }

  clearTextArea(form) {
    const textarea = form.querySelector('textarea[name="content"]');
    const preview = form.querySelector('.preview');

    if (textarea) {
      textarea.value = '';
      textarea.dataset.defaultValue = '';
      this.updateWriteCommentPlaceholderText(textarea);
    }

    if (preview) preview.innerHTML = '';
  }

  updatePostCount() {
    const count = this.container.querySelectorAll('.post.live').length;
    this.container.querySelector('.post_count .count').innerHTML = count;
  }

  modifyReplyCounts(post, n) {
    const posts = this.container.querySelector('.posts');
    while (post) {
      const count = parseInt(post.dataset.replyCount, 10) + n;
      post = posts.querySelector(`#${post.id}`);
      this.setReplyCount(post, count);
      const wrapper = post.parentElement.parentNode.closest('.post__wrapper');
      post = wrapper ? wrapper.querySelector('.post') : undefined;
    }
  }

  setReplyCount(post, count) {
    const actions = ['show', 'hide'];
    actions.forEach((action) => {
      const el = post.querySelector(`[data-action="${action}"] .count`);
      if (el) el.innerHTML = count;
    });
    post.dataset.replyCount = count;
  }

  refreshCommentEvents() {
    const oldPosts = this.container.querySelector('.posts');
    const newPosts = oldPosts.cloneNode(true);
    oldPosts.parentNode.replaceChild(newPosts, oldPosts);
    this.registerCommentButtons(newPosts);
  }

  fetchInitial() {
    if (this.pending) return;
    this.pending = true;

    if (this.commentsFetched) return;
    this.commentsFetched = true;

    this.renderLoading(this.container);

    let endpoint = this.id;

    endpoint += `?parent=${encodeURIComponent(`${window.location.pathname}#comments`)}`;
    if (this.fragment) endpoint += `&fragment=${this.fragment}`;

    const request = new Request(endpoint);
    fetch(request)
      .then((response) => this.checkStatus(response))
      .then((response) => this.renderInitial(response))
      .catch((response) => this.renderLoadingFailed(response));
  }

  renderLoading() {
    this.container.dataset.loaded = false;
    this.container.innerHTML = `<span class="spinner_wrapper">
      <span class="spinner inverted"></span>
      ${this.loc.loading}
    </span>`;
  }

  renderInitial(response) {
    response.text().then((content) => {
      if (!content) return;
      this.container.innerHTML += content;
      this.container.dataset.loaded = true;

      this.updateLocalisation(this.container);
      this.updatePostCount();
      this.registerCommentButtons(this.container.querySelector('.posts'));
      this.registerRootPostForm();
      this.registerInlinePostForm();

      this.container.dataset.order = this.getOrderCookie();
      this.registerFilters();

      const pinnedPost = this.container.querySelector('.post[data-pinned="true"]');
      if (pinnedPost) pinnedPost.dataset.collapsed = true;

      if (this.container.dataset.order !== this.defaultOrder) this.refreshPostOrder();
      this.scrollToAnchor();

      this.pending = false;
      window.dispatchEvent(new Event('BrockmanCommentsRendered'));
    });
  }

  renderLoadingFailed() {
    this.container.dataset.loaded = true;
    this.renderAlert(this.container, this.loc.errorMessage);
  }

  addAlertAfterPost(post, text, type) {
    // Render after last child element of post (which isn't "replies")
    [...post.children].reverse().some((el) => {
      if (!el.classList.contains('replies')) {
        this.renderAlert(post.querySelector('.what'), text, type);
        return true;
      }
      return false;
    });
  }

  addAlertAfterForm(form, text, type) {
    this.renderAlert(form, text, type);
  }

  renderAlert(after, text, classList = 'error') {
    this.removeAllAlerts();
    const alert = document.createElement('div');
    alert.classList = `alert temp ${classList}`;
    alert.innerHTML = `<ul><li>${text}</li></ul>`;
    this.insertAfter(alert, after);
  }

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  removeAllAlerts(container = this.container) {
    const alerts = container.querySelectorAll('.alert.temp');
    alerts.forEach((alert) => alert.remove());
  }

  setPending(isPending) {
    this.container.dataset.pending = isPending;
    this.isPending = isPending;
  }

  scrollToAnchor() {
    if (!this.anchor || this.anchor === 'comments') return;
    const target = document.getElementById(this.anchor);
    if (!target) return;

    // If trying to scroll to a collapsed reply (ie a pinned comment) then
    // uncollapse it
    const pinned = target.closest('.posts > .post[data-collapsed="true"]');
    if (pinned) pinned.dataset.collapsed = false;

    target.scrollIntoView();
    target.classList.add('highlight');
  }

  scrollToElement(target) {
    return target.scrollIntoViewIfNeeded ? target.scrollIntoViewIfNeeded() : target.scrollIntoView();
  }

  handlePostingComment(e, isEditing) {
    e.preventDefault();

    if (this.pending) return;

    const form = e.target.closest('form');
    if (!form) return;

    // Can't post if the textarea is empty
    const textarea = form.querySelector('textarea[name="content"]');
    const { value } = textarea;
    const { defaultValue } = textarea.dataset;
    if (['', defaultValue].includes(value.trim())) {
      this.addAlertAfterForm(form, this.loc.emptyCommentError);
      return;
    }

    const post = e.target.closest('.post');
    this.request('POST', form.action, post, form, isEditing);
  }

  request(method, endpoint, post, form, isEditing) {
    const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

    this.setPending(true);

    fetch(new Request(endpoint), {
      method,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrfToken,
      },
      body: new FormData(form),
    })
      .then((response) => this.checkStatus(response))
      .then((response) => this.processCommentResponse(response, form, post, isEditing))
      .catch((err) => {
        err.then((error) => this.addAlertAfterForm(form, error));
      });
  }

  checkStatus(response) {
    this.setPending(false);
    if (response.status >= 200 && response.status < 300 && response.redirected === false)
      return Promise.resolve(response);
    return Promise.reject(response.text().then((comment) => this.extractErrors(comment)));
  }

  extractErrors(comment) {
    if (!comment.includes('errorlist')) return this.loc.genericError;
    const errorMessageMatch = comment.match(/<ul class="errorlist"><li>(.*)<\/li><\/ul>/);
    return errorMessageMatch[1];
  }

  processCommentResponse(response, form, post, isEditing) {
    this.closeAllEditing();
    response.text().then((comment) => {
      this.clearTextArea(form);
      if (!isEditing) this.renderNewComment(post, comment);
      else this.renderUpdatedComment(post, comment);
    });
  }

  getClosestAllowedWrapper(post) {
    // Get the closest wrapper that is within the indentation limit
    const { hashid } = post.dataset;
    const wrappers = this.container.querySelectorAll(`.post__wrapper:has(.post[data-hashid="${hashid}"])`);
    if (!wrappers.length) return post.parentElement;
    const { maxPostIndent } = post.closest('.thread').dataset;
    const index = Math.min(parseInt(maxPostIndent || 1, 10), wrappers.length) - 1;
    return wrappers[index];
  }

  renderNewComment(parentPost, comment) {
    const isReply = !!parentPost;
    let postContainer = null;

    if (!isReply) {
      // Add post at the top level
      postContainer = this.container.querySelector('.posts');
    } else {
      // Add post as a reply to another post
      this.closeInlinePostForm();
      parentPost.dataset.collapsed = false;
      const wrapper = this.getClosestAllowedWrapper(parentPost);
      postContainer = wrapper.querySelector('.post__children');
      if (!postContainer) {
        wrapper.innerHTML += `<div class="post__children"></div>`;
        postContainer = wrapper.querySelector('.post__children');
      }
      this.modifyReplyCounts(wrapper.querySelector('.post'), 1);
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, 'text/html');
    const { hashid } = doc.body.querySelector('.post').dataset;

    postContainer.innerHTML += `<div class="post__wrapper">${comment}</div>`;

    this.updateLocalisation(postContainer);
    this.refreshPostOrder();
    this.refreshCommentEvents();
    this.updatePostCount();

    // Get the new post after the DOM is refreshed
    const post = this.container.querySelector(`.post[data-hashid='${hashid}']`);
    this.addAlertAfterPost(post, this.loc.submittedComment, 'success');
    this.scrollToElement(post);

    this.sendToPiwik(isReply ? 'comment reply' : 'comment added');
    this.sendToPermutive(isReply ? 'Replied' : 'Added');
  }

  renderUpdatedComment(post, comment) {
    const { hashid, replyCount } = post.dataset;
    post.outerHTML = comment;
    this.updateLocalisation(post);
    this.refreshCommentEvents();

    // Get the new post after the DOM is refreshed
    const updated = this.container.querySelector(`.post[data-hashid='${hashid}']`);
    this.setReplyCount(updated, replyCount);
  }
}

export default class BrockmanTables {
  constructor({ device, loc }) {
    this.device = device;

    this.loc = loc || {
      filter: 'Filter table',
      nothingFound: 'No rows match this search term.',
      reset: 'Clear search',
    };

    this.tables = [];
    this.scrollableTables = [];
    this.searchIndex = [];

    this.register();
  }

  /**
   * Register events to detect when the window is resized (only registers events
   * once, not once per table).
   */
  register() {
    window.addEventListener('resize', () => this.resize(), { passive: true });
    window.addEventListener('orientationchange', () => this.resize());
  }

  /**
   * Update scrollable table properties on window resize or orientation change.
   */
  resize() {
    this.scrollableTables.forEach((table) => this.makeScrollable(table));
  }

  /**
   * Process any tables found within the element selected by the provided
   * selector. Ignores tables which have already been processed.
   */
  addTables(container) {
    const tables = (container || document).querySelectorAll('table');
    tables.forEach((table) => this.hydrate(table));
  }

  /**
   * Process a table if it has yet to be processed.
   */
  hydrate(table) {
    if (this.tables.includes(table)) return;

    this.wrap(table);
    this.indexRows(table);
    this.setMobileMode(table);
    this.makeSortable(table);
    this.makeSearchable(table);

    this.tables.push(table);
  }

  /**
   * Does the provided table have the provided setting enabled? Settings are
   * defined as a comma-separated list in the `data-settings` attribute on the
   * table element.
   */
  hasSetting(table, setting) {
    const { settings } = table.dataset;
    return (settings || '').split(',').includes(setting);
  }

  /**
   * Is the provided string a valid number?
   */
  isNumber(str) {
    return !Number.isNaN(parseFloat(str));
  }

  /**
   * Convert string to lowercase and replace diacritics. This is used to enable
   * searches for "pokemon" to return things containing "Pokémon" for example.
   */
  normalize(str) {
    return str
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .trim();
  }

  /**
   * Wrap the table in a div, to allow us to keep it together with other
   * elements such as the search box (otherwise ads can get inserted between
   * them).
   */
  wrap(table) {
    const wrapper = document.createElement('div');
    wrapper.classList.add('table-wrapper');
    table.parentNode.insertBefore(wrapper, table);
    wrapper.append(table);
  }

  /**
   * Split a table into body rows and header cells. If the table contains a
   * separate <thead> then get any <th> from inside the first row within the
   * <thead>. If not, then get any <th> from the first row within the <tbody>.
   */
  getTableRowsAndHeaders(table) {
    const rows = [...table.querySelectorAll('tbody tr')];
    let headers = [...table.querySelectorAll('thead tr:first-of-type td, thead tr:first-of-type th')];

    // Get header cells from first row if there's no <thead>
    if (rows.length && !headers.length) {
      headers = [...rows[0].querySelectorAll('th')];
      if (headers.length) rows.shift();
    }

    return [rows, headers];
  }

  /**
   * Assign an index number to each row within a table (ignores header row if
   * one is present).
   */
  indexRows(table) {
    // Clean any existing data-index just in case
    table.querySelectorAll('tr[data-index]').forEach((tr) => tr.removeAttribute('data-index'));

    // Add data-index to body rows (avoid header rows)
    const [rows] = this.getTableRowsAndHeaders(table);
    rows.forEach((tr, index) => {
      tr.dataset.index = index;
    });
  }

  /**
   * If the user is on mobile then apply the appropriate mobile mode depending
   * on the `data-mobile-mode` setting.
   */
  setMobileMode(table) {
    if (this.device !== 'mobile') return;

    // Clear existing stacked setting just in case
    table.dataset.stacked = false;

    switch (table.dataset.mobileMode) {
      case 'stack':
        this.makeStackable(table);
        break;

      case 'scroll':
      default:
        this.makeScrollable(table);
        break;
    }
  }

  /**
   * Create a new vertically stacked version of the table contents. Each cell
   * appears on its own row, alongside a copy of the corresponding header cell.
   */
  makeStackable(table) {
    const [rows, headers] = this.getTableRowsAndHeaders(table);
    if (!headers.length) return;

    const fragment = document.createDocumentFragment();

    rows.forEach((tr, row) => {
      const cells = tr.querySelectorAll('td');
      cells.forEach((td, column) => {
        const stackedRow = document.createElement('tr');
        const stackedHeader = document.createElement('th');

        const header = headers[column];
        if (header) stackedHeader.innerHTML = header.innerHTML;

        stackedRow.dataset.index = row;
        stackedRow.append(stackedHeader);
        stackedRow.append(td);
        fragment.append(stackedRow);
      });
    });

    // Replace contents of table with stacked version
    table.innerHTML = '';
    table.append(fragment);
    table.dataset.stacked = true;

    // Register lightbox images
    if (window.brockmanLightbox) {
      window.brockmanLightbox.run(table);
    }

    this.alternateFilled(table);
  }

  /**
   * Visible sets of rows should alternate between filled and unfilled
   * backgrounds when the table is stacked.
   */
  alternateFilled(table) {
    if (table.dataset.stacked !== 'true') return;

    const rows = table.querySelectorAll('tr[data-index]');
    let previousIndex;
    let isFilled = true;

    rows.forEach((tr) => {
      const { index } = tr.dataset;
      const isHidden = tr.classList.contains('hidden');

      if (!isHidden && previousIndex !== index) {
        previousIndex = index;
        isFilled = !isFilled;
      }

      if (isHidden || !isFilled) tr.classList.remove('filled');
      else tr.classList.add('filled');
    });
  }

  /**
   * If a table's content overflows its container, it should overflow scroll.
   * Set various paramters to allow the overflow shadow to appear in the correct
   * place.
   */
  makeScrollable(table) {
    // Space between each side of the table's container and the edge of the screen (px)
    const scrollOffset = (window.innerWidth - table.parentNode.offsetWidth) / 2;

    // Does the table overflow?
    const shouldScroll = table.scrollWidth > window.innerWidth - scrollOffset * 2;
    table.dataset.scroll = shouldScroll;
    if (!shouldScroll) return;

    // Full width of the table content (px)
    const { scrollWidth } = table;

    table.style.setProperty('--table-scroll-width', `${scrollWidth}px`);
    table.style.setProperty('--table-scroll-offset', `${scrollOffset}px`);
    this.scrollableTables.push(table);
  }

  /**
   * If a table is sortable and not stacked then add sortable styling and click
   * events to the header cells.
   */
  makeSortable(table) {
    if (!this.hasSetting(table, 'sortable') || table.dataset.stacked === 'true') return;
    const [rows, headers] = this.getTableRowsAndHeaders(table);

    // Add sort event to header cells
    headers.forEach((th, columnIndex) => {
      if (th.innerText.trim()) {
        th.classList.add('sortable');
        th.addEventListener('click', () => this.clickSort(th, table, headers, rows, columnIndex));
      }
    });
  }

  /**
   * When a header cell is clicked, alternate between sort modes (ascending ->
   * descending -> default). Ascending and descending sort modes order rows by
   * that column's content. The default mode orders rows by the original row
   * index.
   */
  clickSort(th, table, headers, rows, columnIndex) {
    // Reset sort header
    headers.forEach((el) => {
      if (el !== th) el.dataset.sort = '';
    });

    // Update sort mode
    const sortMode = this.getNextSortMode(th.dataset.sort);
    th.dataset.sort = sortMode;

    // Get sort function
    let sort;
    switch (sortMode) {
      case 'asc':
        sort = this.getSortByCellContentAsc();
        break;

      case 'desc':
        sort = this.getSortByCellContentDesc();
        break;

      case '':
      default:
        sort = this.sortByRowIndex;
        break;
    }

    // Get new row order
    const tbody = table.querySelector('tbody');
    const reordered = rows.sort((a, b) => sort(a, b, columnIndex));

    // Apply row order
    reordered.forEach((tr) => {
      tbody.append(tr);
    });
  }

  getSortByCellContentAsc() {
    return (a, b, columnIndex) => this.sortByCellContent(a, b, columnIndex);
  }

  getSortByCellContentDesc() {
    return (a, b, columnIndex) => this.sortByCellContent(b, a, columnIndex);
  }

  /**
   * Compare two <tr> elements a and b, and order by the content of the <td> at
   * the provided columnIndex. Takes locale into account, and allows numbers to
   * be correctly ordered numerically.
   */
  sortByCellContent(a, b, columnIndex) {
    const getCellContent = (tr) => {
      const td = tr.querySelectorAll('td')[columnIndex];
      return td ? td.innerText.trim() : '';
    };

    const aText = getCellContent(a);
    const bText = getCellContent(b);
    const aNumber = parseFloat(aText);
    const bNumber = parseFloat(bText);

    // If both are numbers then compare numerically
    if (this.isNumber(aNumber) && this.isNumber(bNumber)) return aNumber - bNumber;

    return aText.localeCompare(bText);
  }

  /**
   * Compare two <tr> elements a and b, and order by the original `data-index`
   * of each row.
   */
  sortByRowIndex(a, b) {
    const getIndex = (tr) => parseInt(tr.dataset.index, 10);
    return getIndex(a) - getIndex(b);
  }

  /**
   * Get the next sort mode given the current sort mode (ascending -> descending
   * -> default).
   */
  getNextSortMode(currentSortMode) {
    switch (currentSortMode) {
      case 'asc':
        return 'desc';

      case 'desc':
        return '';

      case '':
      default:
        return 'asc';
    }
  }

  /**
   * If a table is searchable then insert a search box and insert it before the
   * table, then register an input event to filter the table when the user
   * enters characters.
   */
  makeSearchable(table) {
    if (!this.hasSetting(table, 'searchable')) return;

    // Create wrapper
    const searchWrapper = document.createElement('div');
    searchWrapper.classList = 'search';
    table.parentNode.insertBefore(searchWrapper, table);

    // Create search input
    const searchInput = document.createElement('input');
    searchInput.ariaLabel = this.loc.filter;
    searchInput.placeholder = this.loc.filter;
    searchInput.type = 'text';
    searchInput.addEventListener('input', () => this.search(searchInput, table));
    searchWrapper.append(searchInput);

    // Create reset button
    const resetButton = document.createElement('button');
    resetButton.ariaLabel = this.loc.reset;
    resetButton.title = this.loc.reset;
    resetButton.type = 'button';
    resetButton.classList.add('reset-button');
    resetButton.addEventListener('click', () => this.resetSearch(searchInput, table));
    searchWrapper.append(resetButton);

    // Create "nothing found" alert
    const alert = document.createElement('div');
    alert.classList.add('nothing-found', 'alert', 'info', 'hidden');
    alert.innerText = this.loc.nothingFound;
    table.parentNode.append(alert);

    this.setFixedWidth(table);
  }

  /**
   * Table columns could change width to fit content depending on which rows are
   * visible. This can be a bit jarring, so to avoid this we can set the width
   * of each cell to its initial width.
   */
  setFixedWidth(table) {
    const cells = table.querySelectorAll('td, th');
    cells.forEach((td) => {
      td.width = td.offsetWidth;
    });
  }

  /**
   * Get the "search index" for a table if one has already been set up,
   * otherwise create one. This consists of all of the text for each row of a
   * table, which is cleaned up and indexed by the row's `data-index`.
   */
  getSearchIndex(table) {
    const tableIndex = this.tables.indexOf(table);

    // Build search index if it doesn't exist already
    if (!this.searchIndex[tableIndex]) {
      const searchIndex = [];
      const rows = table.querySelectorAll('tr[data-index]');

      rows.forEach((tr) => {
        const cells = tr.querySelectorAll('td');
        const rowIndex = tr.dataset.index;

        searchIndex[rowIndex] = searchIndex[rowIndex] || '';
        cells.forEach((td) => {
          searchIndex[rowIndex] += `${td.innerText.trim()}\t`;
        });

        searchIndex[rowIndex] = this.normalize(searchIndex[rowIndex]);
      });

      this.searchIndex[tableIndex] = searchIndex;
    }

    return this.searchIndex[tableIndex];
  }

  /*
   * Use the "search index" for a table to work out which rows should be visible
   * based on the user's search query, then toggle visibility of all rows as
   * appropriate.
   */
  search(seachInput, table) {
    const query = this.normalize(seachInput.value);
    const searchIndex = this.getSearchIndex(table);
    const rows = table.querySelectorAll('tr[data-index]');

    const visibleRows = searchIndex.reduce((acc, text, index) => {
      if (text.includes(query)) acc.push(index);
      return acc;
    }, []);

    rows.forEach((tr) => {
      const index = parseInt(tr.dataset.index, 10);
      if (visibleRows.includes(index)) tr.classList.remove('hidden');
      else tr.classList.add('hidden');
    });

    table.dataset.visibleRows = visibleRows.length;
    this.alternateFilled(table);
  }

  /**
   * Clear the search and show all the table rows.
   */
  resetSearch(searchInput, table) {
    searchInput.value = '';
    this.search(searchInput, table);
  }
}

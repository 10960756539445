export default class BrockmanLiveblog {
  /**
   * Set up liveblog feeds to poll endpoints and update the DOM with new,
   * updated, or removed posts as necessary.
   *
   * Feed endpoints must return JSON in this format:
   * @type {number} updated - Unix Timestamp for when the feed was last updated
   * @type {boolean} live - disables polling if set to false
   * @type {Array.<object>} posts - post objects to render:
   *   @type {string} hashid - ID hash for this post
   *   @type {string} [content] - markup to render for this post
   *   @type {string} [userHashid] - ID hash for the user who made the post
   */

  /**
   * @param {Array.<object>} feeds - feeds to register (see format below)
   * @param {string} user - hash ID for the current user (used to determine if
   *   they own a post)
   * @param {number} maxAttempts - how many consecutive get requests must fail
   *   before giving up on trying to update a feed
   * @param {number} updatesCount - how many new posts with a title have been
   *  returned while the user is on the Coverage tab and not in view of the post
   * @param {number} coverageCount - how many new coverage posts have been
   *  returned while the user is on the Comments tab
   * @param {number} commentCount - how many new comment posts have been
   *  returned while the user is on the Coverage tab
   * @param {Element} updatesButton - button element for displaying new updates count
   * @param {object} loc - localisation / internationalisation strings
   */
  constructor({ user, maxAttempts, loc, commentsRenderLimit }) {
    this.feeds = [];
    this.user = user;
    this.maxAttempts = maxAttempts || 10;
    this.updatesCount = 0;
    this.coverageCount = 0;
    this.commentCount = 0;
    this.commentCountLimit = commentsRenderLimit - 1 || null;

    this.tabs = null;

    this.coverageTab = document.querySelector(
      '.tabbed_button[data-tab=".liveblog_coverage"]',
    );
    this.commentTab = document.querySelector(
      '.tabbed_button[data-tab=".liveblog_comments"]',
    );
    this.coverage = document.querySelector('.liveblog_coverage');
    this.comments = document.querySelector('.liveblog_comments');

    this.loc = loc || {
      error: `Something went wrong.`,
      writeComment: [`Write a spectacular comment`],
    };

    this.registerCommentsAnchorLink();
  }

  addFeeds(feeds) {
    feeds.forEach((feed) => this.addFeed(feed));
  }

  /**
   * @param {number} repeat - how often to poll the endpoint (ms)
   * @param {element} formContainer - inject the reply form inside this
   * @param {string} formEndpoint - get the reply form from here (HTML)
   * @param {element} postsContainer - inject the posts inside this
   * @param {string} postsEndpoint - get the posts from here (JSON)
   * @param {number} postsRenderLimit - how many post elements to render at once
   * @param {number} postsLoadMoreButton - click this element to load in more posts
   */
  addFeed({
    repeat,
    formContainer,
    formEndpoint,
    postsContainer,
    postsEndpoint,
    postsRenderLimit,
    postsLoadMoreButton,
  }) {
    const postsAutoDelete = postsRenderLimit > 0;
    const feed = {
      formContainer,
      formEndpoint,
      postsContainer,
      postsEndpoint,
      postsRenderLimit,
      postsAutoDelete,
      postsLoadMoreButton,
      repeat,
      timeout: null,
      updated: null,
      attempts: 0,
    };
    this.feeds.push(feed);
    this.loadPosts(feed);
    this.getForm(feed);
    this.registerKeyPoint();
    this.registerLoadMoreButton(feed);
  }

  addTabs(tabs) {
    this.tabs = tabs;
    this.tabs.registerCallback((tab) => {
      this.resetCounts();
      if (tab) tab.scrollIntoView(true);
    });
  }

  registerLoadMoreButton(feed) {
    const { postsLoadMoreButton } = feed;
    if (!postsLoadMoreButton) return;
    postsLoadMoreButton.addEventListener('click', () => {
      postsLoadMoreButton.setAttribute('disabled', true);
      feed.postsAutoDelete = false;
      this.loadPosts(feed);
    });
  }

  startTimeout(feed) {
    if (!feed.repeat) return;
    feed.timeout = setTimeout(() => this.updatePosts(feed), feed.repeat);
  }

  stopTimeout(feed) {
    if (!feed.timeout) return;
    clearTimeout(feed.timeout);
    feed.timeout = null;
  }

  async updatePosts(feed) {
    if (!feed.postsContainer || !feed.postsEndpoint) return;
    if (document.hidden) {
      this.startTimeout(feed);
      return;
    }

    const url = this.getFeedUrl(feed);
    if (feed.updated) {
      url.search = new URLSearchParams({
        since: feed.updated,
      }).toString();
    }

    await this.feedRequest(url, true, feed);
    this.startTimeout(feed);
  }

  async loadPosts(feed) {
    if (!feed.postsContainer || !feed.postsEndpoint) return;

    const url = this.getFeedUrl(feed);
    const timestamp = this.getEarliestTimestamp(feed);
    if (timestamp) {
      url.search = new URLSearchParams({
        before: timestamp,
      }).toString();
    }

    await this.feedRequest(url, false, feed);
    this.startTimeout(feed);
  }

  getEarliestTimestamp(feed) {
    const { postsContainer } = feed;

    const element = postsContainer.querySelector(
      '.post[data-created-at]:last-of-type',
    );

    if (element) {
      const { createdAt } = element.dataset;
      const timestamp = this.convertToTimestamp(createdAt);
      if (timestamp) return timestamp;
    }

    return null;
  }

  getFeedUrl(feed) {
    return new URL(feed.postsEndpoint, window.location.origin);
  }

  async feedRequest(url, prepend, feed) {
    fetch(url)
      .then((response) => this.status(response, feed.postsContainer, true))
      .then((response) => response.json())
      .then((result) => {
        const firstRequest = !feed.updated;
        this.resetAttempts(feed);
        this.renderFeed(result, prepend, feed);
        if (firstRequest) this.checkAnchor();
      })
      .catch(() => {
        this.incrementAttempts(feed);
        this.renderAlert(this.loc.error, feed.postsContainer, true);
      });
  }

  incrementAttempts(feed) {
    let { attempts } = feed;
    attempts += 1;
    feed.attempts = attempts;
    if (attempts >= this.maxAttempts) this.stopTimeout(feed);
  }

  resetAttempts(feed) {
    feed.attempts = 0;
  }

  registerKeyPoint() {
    const points = document.querySelectorAll('.timeline_list .item_title');
    points.forEach((point) =>
      point.addEventListener('click', () => this.activateCoverageTab()),
    );
  }

  resetCounts() {
    this.commentCount = 0;
    this.coverageCount = 0;
  }

  activateCoverageTab() {
    if (this.tabs) this.tabs.click(this.coverageTab);
  }

  activateCommentTab() {
    if (this.tabs) this.tabs.click(this.commentTab);
  }

  getForm(feed) {
    const { formContainer, formEndpoint } = feed;
    if (!formContainer || !formEndpoint) return;

    const url = new URL(formEndpoint, window.location.origin);
    url.searchParams.append('parent', `${window.location.pathname}#comments`);

    fetch(url)
      .then((response) => this.status(response, formContainer))
      .then((response) => response.text())
      .then((result) => this.renderForm(result, feed))
      .catch(() => this.renderAlert(this.loc.error, formContainer));
  }

  postForm(event, feed) {
    event.preventDefault();
    const { formContainer, postsContainer } = feed;
    if (!formContainer || !postsContainer) return;

    const form = event.target.closest('form');
    const formData = new FormData(form);
    const url = new URL(form.action, window.location.origin);

    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => this.status(response, formContainer))
      .then((response) => response.text())
      .then((result) => {
        this.clearForm(form);
        this.createPost(result, this.user, postsContainer);
        this.cullOldPosts(feed);
      })
      .catch(() => this.renderAlert(this.loc.error, formContainer));
  }

  clearForm(form) {
    const textarea = form.querySelector('textarea');
    if (!textarea) return;
    textarea.value = '';
    this.updateWriteCommentPlaceholderText(textarea);
  }

  status(response, container) {
    if (response.ok && response.status === 200) {
      this.removeAlert(container);
      return response;
    }
    response.text().then((error) => {
      const extracted = this.extractError(error);
      if (extracted) this.renderAlert(extracted, container);
    });
    throw new Error('Response not OK');
  }

  extractError(markup) {
    if (markup && markup.includes('errorlist')) {
      const match = markup.match(/<ul class="errorlist"><li>(.*?)<\/li>/);
      if (match.length > 1) return match[1];
    }
    return null;
  }

  removeAlert(container) {
    if (!container) return;
    const alert = container.querySelector('.alert');
    if (alert) alert.remove();
  }

  renderAlert(message, container, prepend = false, type = 'error') {
    if (!message || !container) return;
    this.removeAlert(container);
    const div = document.createElement('div');
    div.innerHTML = message;
    div.classList = `alert ${type}`;
    if (prepend) container.prepend(div);
    else container.appendChild(div);
  }

  convertToTimestamp(str) {
    if (str) {
      const timestamp = Number(str);
      if (!Number.isNaN(timestamp)) return timestamp;
    }
    return null;
  }

  setUpdatedTimestamp(feed, data) {
    const timestamp = this.convertToTimestamp(data.updated);
    if (!timestamp) return;
    if (!feed.updated) feed.updated = timestamp;
    else if (timestamp > feed.updated) feed.updated = timestamp;
  }

  updateLoadMoreButton(feed, data) {
    const { postsContainer, postsLoadMoreButton } = feed;
    if (!postsLoadMoreButton) return;
    postsLoadMoreButton.removeAttribute('disabled');

    const { firstHashid } = data;
    if (!firstHashid) return;
    const element = postsContainer.querySelector(
      `.post[data-hashid="${firstHashid}"]`,
    );

    if (element) postsLoadMoreButton.classList.add('hidden');
    else postsLoadMoreButton.classList.remove('hidden');
  }

  renderFeed(data, prepend, feed) {
    const { posts, live } = data;

    this.setUpdatedTimestamp(feed, data);

    if (!posts) return;

    const fragment = document.createDocumentFragment();
    posts.forEach((post) => this.processPost(post, feed, fragment));
    if (prepend) feed.postsContainer.prepend(fragment);
    else feed.postsContainer.append(fragment);
    this.renderTabCount();

    if (!live) this.stopTimeout(feed);
    this.cullOldPosts(feed);
    this.updateLoadMoreButton(feed, data);
  }

  postsOverLimit(feed) {
    const { postsRenderLimit, postsContainer } = feed;
    if (!postsRenderLimit) return 0;
    const postCount = postsContainer.querySelectorAll('.post').length;
    return Math.max(postCount - postsRenderLimit, 0);
  }

  cullOldPosts(feed) {
    const { postsAutoDelete, postsContainer } = feed;
    if (!postsAutoDelete) return;
    const count = this.postsOverLimit(feed);
    if (!count) return;
    const posts = [...postsContainer.querySelectorAll('.post')].slice(-count);
    posts.forEach((element) => this.deletePost(element));
  }

  renderForm(markup, feed) {
    const { formContainer } = feed;
    if (!markup || !formContainer) return;
    const div = document.createElement('div');
    div.innerHTML = markup;
    formContainer.appendChild(div.firstElementChild);
    div.remove();
    const form = formContainer.querySelector('form');
    if (form) {
      form.addEventListener('submit', (event) => this.postForm(event, feed));
      this.updateWriteCommentPlaceholderText(form.querySelector('textarea'));
    }
  }

  updateWriteCommentPlaceholderText(textarea) {
    if (textarea) textarea.placeholder = this.getWriteCommentPlaceholderText();
  }

  getWriteCommentPlaceholderText() {
    const { writeComment } = this.loc;
    return writeComment[Math.floor(Math.random() * writeComment.length)];
  }

  processPost(post, feed, parent) {
    const { content, hashid, userHashid } = post;
    const { postsContainer } = feed;
    const element = postsContainer.querySelector(
      `.post[data-hashid="${hashid}"]`,
    );

    if (!element && content) {
      this.createPost(content, userHashid, parent);
      return;
    }

    if (element && element.classList.contains('coverage_post')) {
      const keyPoint = document.querySelector(
        `.timeline_item[data-hashid="${hashid}"]`,
      );
      if (keyPoint && content) {
        this.processCoveragePost(content, keyPoint);
      } else if (keyPoint && !content) keyPoint.remove();
    }

    if (element && !content) {
      this.deletePost(element);
      return;
    }

    if (element) {
      this.updatePost(element, post);
    }
  }

  processCoveragePost(content, keyPoint) {
    const { title } = this.getDetailsFromContent(content);
    if (title) {
      this.updateKeyPoint(keyPoint, title);
    } else {
      const list = keyPoint.closest('.timeline_list');
      list.dataset.items = parseInt(list.dataset.items, 10) - 1;
      keyPoint.remove();
    }
  }

  createPost(content, userHashid, parent) {
    const div = document.createElement('div');
    div.innerHTML = content;
    const element = div.firstElementChild;
    this.hydratePost(element, userHashid);
    parent.prepend(element);
    div.remove();
    this.updateUnreadCounts(element);
  }

  isCurrentUser(userHashid) {
    if (this.user && userHashid && this.user === userHashid) return true;
    return false;
  }

  updateUnreadCounts(post) {
    const isCoverage = post.classList.contains('coverage_post');
    if (isCoverage) this.coverageCount += 1;
    else this.commentCount += 1;
  }

  deletePost(element) {
    element.remove();
  }

  updatePost(target, post) {
    const { content, userHashid } = post;
    const div = document.createElement('div');
    div.innerHTML = content;
    const element = div.firstChild;
    this.hydratePost(element, userHashid);
    target.replaceWith(element);
    div.remove();
  }

  updateKeyPoint(keyPoint, title) {
    const el = keyPoint.querySelector('.item_title');
    el.innerText = title.innerText;
  }

  getDetailsFromContent(content) {
    const div = document.createElement('div');
    div.innerHTML = content;
    const contentElement = div.firstElementChild;
    const title = contentElement.querySelector('.title');
    const date = contentElement.querySelector('.datetime');
    return { title, date };
  }

  renderTabCount() {
    // When on the comments or coverage tabs and a new post is not visible, display the
    // count alongside the tab instead of in the updates button
    const coverageHidden = this.coverage.classList.contains('hidden');
    const commentsHidden = this.comments.classList.contains('hidden');

    if (coverageHidden)
      this.updateTabCount(this.coverageTab, this.coverageCount);
    else if (commentsHidden) {
      let { commentCount } = this;
      if (this.commentCount > this.commentCountLimit)
        commentCount = `${this.commentCountLimit}+`;
      this.updateTabCount(this.commentTab, commentCount);
    }
  }

  updateTabCount(tab, count) {
    const countElement = tab.querySelector('.tabbed_button_count');
    if (count === 0) return;
    const text = `(${count})`;
    if (countElement) countElement.innerText = text;
    else tab.innerHTML += `<span class="tabbed_button_count">${text}</span>`;
  }

  enableElements(target) {
    const embeds = target.querySelectorAll(
      'iframe[data-src], script[data-src], img[data-src]',
    );
    embeds.forEach((element) => {
      element.setAttribute('src', element.dataset.src);
    });
  }

  hydratePost(element, userHashid) {
    if (window.brockmanLightbox) window.brockmanLightbox.run(element);
    if (window.brockmanTables) window.brockmanTables.addTables(element);
    if (window.BrockmanAutoTimeZone) window.BrockmanAutoTimeZone.run(element);

    if (
      window.BrockmanAllowedCookies &&
      window.BrockmanAllowedCookies.targeting
    )
      this.enableElements(element);

    if (this.isCurrentUser(userHashid)) element.classList.add('current');
  }

  registerCommentsAnchorLink() {
    [...document.querySelectorAll('.comments__link')].forEach((button) =>
      button.addEventListener('click', () => this.scrollToCommentsTab()),
    );
  }

  checkAnchor() {
    const anchor = this.getAnchor();
    if (!anchor) return;
    this.scrollToCommentsTab(anchor);
  }

  getAnchor() {
    const [anchor, hash] = window.location.hash.split('-');
    if (anchor === '#comments' || (anchor === '#comments' && hash))
      return window.location.hash.slice(1);
    return null;
  }

  scrollToCommentsTab(anchor = 'comments') {
    this.activateCommentTab();
    const target = document.getElementById(anchor);
    if (!target) return;
    target.scrollIntoView();
    target.classList.add('highlight');
  }
}

export default class BrockmanCardHover {
  constructor() {
    this.card_width = 200;
    this.card_height = 276;
    this.cache = {};
    this.frame = document.createElement('span');
    this.frame.classList.add('hidden');
    this.frame_img = document.createElement('img');
    this.frame.appendChild(this.frame_img);
    this.frame.id = 'card-hover-container';
    document.body.appendChild(this.frame);
  }

  run() {
    const targets = document.querySelectorAll('.card-hover:not([data-init])');
    targets.forEach((target) => {
      target.addEventListener('mouseenter', this.enter.bind(this));
      target.addEventListener('mouseleave', this.leave.bind(this));
      target.addEventListener('click', this.click.bind(this));
      target.dataset.init = true;
    });
  }

  click(e) {
    if (e) e.preventDefault();
    this.enter(e);
  }

  enter(e) {
    if (e) e.preventDefault();
    this.x_event_location = e.clientX;
    this.y_event_location = e.clientY;
    const tip = e.target;
    this.id = tip.getAttribute('data-card').toLowerCase();
    this.game = tip.getAttribute('data-game');
    this.open();
  }

  leave(e) {
    if (e) e.preventDefault();
    this.close();
  }

  open() {
    this.frame_img.src = '';
    if (this.cache[this.game] && this.cache[this.game][this.id]) {
      this.render();
    } else {
      const endpoint = `/card-hover/${this.game}/${this.id}`;
      const request = new Request(endpoint);
      fetch(request)
        .then((response) => this.complete(response))
        .catch(() => this.close());
    }
  }

  complete(response) {
    if (response.ok && response.status === 200) {
      response.text().then((text) => {
        const responseObj = JSON.parse(text);
        const src = responseObj.img_src;
        if (!this.cache[responseObj.game]) {
          this.cache[responseObj.game] = {};
        }
        this.cache[responseObj.game][responseObj.card] = src;
        this.render();
      });
    }
  }

  render() {
    if (this.y_event_location > window.innerHeight / 2) {
      this.frame.style.top = `${this.y_event_location - this.card_height}px`;
    } else {
      this.frame.style.top = `${this.y_event_location}px`;
    }
    if (this.x_event_location + this.card_width > window.innerWidth) {
      this.frame.style.left = `${window.innerWidth - this.card_width}px`;
    } else {
      this.frame.style.left = `${this.x_event_location}px`;
    }
    this.frame_img.src = this.cache[this.game][this.id];
    this.frame.classList.remove('hidden');
  }

  close() {
    this.frame.classList.add('hidden');
  }
}

export default class BrockmanZoomer {
  constructor({ target, imageWidth, thumbnailWidth, loc }) {
    this.target = target;
    this.imageWidth = imageWidth || 3840;
    this.thumbnailWidth = thumbnailWidth || 102;

    this.loc = loc || {
      about: 'About this image',
      all: 'All',
      close: 'Close',
      controls: 'Controls',
      info: 'Info',
      overlay: 'Overlay images',
      thumbnails: 'Thumbnails',
      title: 'Zoomer',
      zoom: 'Zoom',
    };

    this.classes = {
      about: 'zoomer-about',
      active: 'active',
      button: ['button', 'outline', 'inverted'],
      buttonGroup: ['button_group', 'tabbed'],
      close: 'close',
      controls: 'zoomer-controls',
      details: 'zoomer-details',
      expand: 'expand',
      fullscreen: 'zoomer-fullscreen',
      header: 'zoomer-header',
      hidden: 'hidden',
      info: 'info',
      interact: 'interact',
      label: 'label',
      launcher: 'zoomer-launcher',
      overflowHidden: 'overflow-hidden',
      overlayCheckbox: 'overlay-checkbox',
      spinner: ['spinner', 'large', 'inverted'],
      stage: 'zoomer-stage',
      thumbnails: 'zoomer-thumbnails',
      vertical: 'vertical',
      zoom: 'zoom',
      zoomSlider: 'zoom-slider',
    };

    this.nudgeDistance = 20;
    this.zoomStep = 0.01;
    this.zoomMin = 0.1;
    this.zoomMax = 1;
    this.zoomStart = (this.zoomMax - this.zoomMin) / 2 + this.zoomMin;
    this.zoomWheelScale = 0.001;
    this.verticalThreshold = 720;
    this.thumbnailAspectRatio = 9 / 16;

    this.about = null;
    this.controls = null;
    this.fullscreen = null;
    this.origin = { x: 0, y: 0 };
    this.overlay = false;
    this.overlayCheckbox = null;
    this.pos = { x: 0, y: 0 };
    this.stage = null;
    this.stageHeight = null;
    this.stageImages = [];
    this.stageWidth = null;
    this.thumbnailButtons = [];
    this.thumbnails = null;
    this.vertical = true;
    this.zoomModifier = this.zoomStart;
    this.thumbnailHeight = Math.floor(this.thumbnailWidth * this.thumbnailAspectRatio);

    this.groups = [].slice.call(this.target.querySelectorAll('figure[role="group"]'));
    this.launcherButton = this.target.querySelector(`.${this.classes.launcher} button`);

    this.onKeydownHandler = (e) => this.onKeydown(e);
    this.onResizeHandler = () => this.onResize();
    this.onMoveHandler = (e) => this.onMove(e);
    this.onBlurHandler = () => this.onStageInteractEnd();
    this.onInteractEndHandler = () => this.onStageInteractEnd();
  }

  run() {
    this.target.dataset.init = true;
    if (!this.groups.length) return;
    this.groups.forEach((group) =>
      group.querySelector('a').addEventListener('click', (e) => this.onClickLaunchLink(e)),
    );
    this.launcherButton.addEventListener('click', () => this.onClickLaunchButton());
  }

  onClickLaunchLink(e) {
    e.preventDefault();
    this.launch(e.target.closest('figure[role="group"]'));
  }

  onClickLaunchButton() {
    this.launch(this.groups[0]);
  }

  onClickThumbnailButton(e, group) {
    this.resetPos();
    this.update(group);
    this.deactivateThumbnailButtons();
    const target = e.target.closest('button');
    target.classList.add(this.classes.active);
    this.activateOverlayCheckbox();
    const figures = group.querySelectorAll(':scope > figure');
    this.resetOverlayCheckbox(figures);
  }

  onClickToggleButton(e, figures) {
    this.updateImages(figures);
    this.deactivateToggleButtons();
    const target = e.target.closest('button');
    target.classList.add(this.classes.active);
    this.resetOverlayCheckbox(figures);
  }

  resetOverlayCheckbox(figures) {
    if (figures.length > 1) this.activateOverlayCheckbox();
    else this.deactivateOverlayCheckbox();
  }

  onKeydown(e) {
    switch (e.keyCode) {
      case 27: // escape
        this.destroy();
        break;

      case 37: // left
        this.nudge('x', this.nudgeDistance, e);
        break;

      case 38: // up
        this.nudge('y', this.nudgeDistance, e);
        break;

      case 39: // right
        this.nudge('x', -this.nudgeDistance, e);
        break;

      case 40: // down
        this.nudge('y', -this.nudgeDistance, e);
        break;

      default:
        break;
    }
  }

  onResize() {
    this.calculateStageSize();
    this.calculateOrientation();
    this.drawImages();
  }

  onMove(e) {
    const pos = {
      x: e.pageX || e.changedTouches[0].pageX || this.origin.x,
      y: e.pageY || e.changedTouches[0].pageY || this.origin.y,
    };

    if (this.origin.x === pos.x && this.origin.y === pos.y) return;

    this.pos.x = pos.x - this.origin.x;
    this.pos.y = pos.y - this.origin.y;

    this.drawImages(this.pos);
  }

  onStageInteract(e) {
    this.origin.x = (e.pageX || e.touches[0].pageX) - this.pos.x;
    this.origin.y = (e.pageY || e.touches[0].pageY) - this.pos.y;

    this.fullscreen.addEventListener('mousemove', this.onMoveHandler);
    this.fullscreen.addEventListener('touchmove', this.onMoveHandler);
    this.fullscreen.classList.add(this.classes.interact);
  }

  onStageInteractEnd() {
    this.fullscreen.removeEventListener('mousemove', this.onMoveHandler);
    this.fullscreen.removeEventListener('touchmove', this.onMoveHandler);
    this.fullscreen.classList.remove(this.classes.interact);
  }

  onStageWheel(e) {
    const zoomChange = e.deltaY * this.zoomWheelScale;
    const zoomModifier = Math.min(Math.max(this.zoomModifier - zoomChange, this.zoomMin), this.zoomMax);
    this.zoomSlider.value = zoomModifier;
    this.zoomModifier = zoomModifier;
    this.drawImages();
  }

  onZoomSliderChange(e) {
    this.zoomModifier = parseFloat(e.target.value);
    this.drawImages();
  }

  onOverlayCheckboxChange(e) {
    this.overlay = e.target.checked;
    this.drawImages();
  }

  onClickInfoButton(e) {
    const button = e.target.closest('button');
    if (button.classList.contains(this.classes.active)) {
      button.classList.remove(this.classes.active);
      this.details.classList.remove(this.classes.expand);
    } else {
      button.classList.add(this.classes.active);
      this.details.classList.add(this.classes.expand);
    }
    this.drawImages();
  }

  resetPos() {
    this.pos.x = 0;
    this.pos.y = 0;
  }

  drawImages() {
    if (!this.stageImages.length) return;
    if (!this.stageWidth || this.stageHeight) this.calculateStageSize();

    const { x, y } = this.pos;

    let offset = 0;
    if (this.overlay) {
      const distance = this.vertical ? this.stageHeight : this.stageWidth;
      offset = -distance / this.stageImages.length;
    }

    const size = `${this.zoomModifier * this.imageWidth}px`;

    this.stageImages.forEach((image, index) => {
      const pos = { x, y };
      if (this.vertical) pos.y += index * offset;
      else pos.x += index * offset;

      image.style.backgroundPosition = `calc(50% + ${pos.x}px) calc(50% + ${pos.y}px)`;
      image.style.backgroundSize = size;
    });
  }

  calculateStageSize() {
    if (this.stage) {
      const rect = this.stage.getBoundingClientRect();
      this.stageWidth = rect.width;
      this.stageHeight = rect.height;
    }
  }

  calculateOrientation() {
    this.vertical = window.innerWidth < this.verticalThreshold;
    if (this.vertical) this.stage.classList.add(this.classes.vertical);
    else this.stage.classList.remove(this.classes.vertical);
  }

  nudge(plane, distance, e) {
    if (e && e.target.tagName === 'INPUT') return;
    if (plane === 'x') this.pos.x += distance;
    else if (plane === 'y') this.pos.y += distance;
    this.drawImages();
  }

  deactivateThumbnailButtons() {
    this.thumbnails.querySelectorAll('button').forEach((button) => {
      button.classList.remove(this.classes.active);
    });
  }

  activateThumbnailButton(group) {
    const index = this.groups.indexOf(group);
    const thumbnail = this.thumbnailButtons[index];
    const button = thumbnail.closest('button');
    button.classList.add(this.classes.active);
  }

  deactivateToggleButtons() {
    this.controls.querySelectorAll('button').forEach((button) => {
      button.classList.remove(this.classes.active);
    });
  }

  activateOverlayCheckbox() {
    this.overlayCheckbox.disabled = false;
  }

  deactivateOverlayCheckbox() {
    this.overlayCheckbox.disabled = true;
  }

  render() {
    this.fullscreen = document.createElement('div');
    this.fullscreen.classList.add(this.classes.fullscreen);

    this.fullscreen.innerHTML = `
      <div class="${this.classes.stage}"></div>
      <div class="${this.classes.details}">
        <div class="${this.classes.header}">
          <p class="${this.classes.label}">${this.loc.title}</p>
          <button class="${this.classes.button.join(' ')} ${this.classes.info}">${this.loc.info}</button>
          <button class="${this.classes.button.join(' ')} ${this.classes.close}">${this.loc.close}</button>
        </div>
        <div class="${this.classes.thumbnails}">
          <p class="${this.classes.label}">${this.loc.thumbnails}</p>
          <div></div>
        </div>
        <div class="${this.classes.zoom}">
          <p class="${this.classes.label}">${this.loc.zoom}</p>
          <input
            class="${this.classes.zoomSlider}"
            type="range"
            step="${this.zoomStep}"
            min="${this.zoomMin}"
            max="${this.zoomMax}"
            value="${this.zoomStart}"
          >
        </div>
        <div class="${this.classes.controls}">
          <p class="${this.classes.label}">${this.loc.controls}</p>
          <div class="${this.classes.buttonGroup.join(' ')}"></div>
          <label class="${this.classes.overlayCheckbox}">
            <input type="checkbox">
            ${this.loc.overlay}
          </label>
        </div>
        <div class="${this.classes.about}">
          <p class="${this.classes.label}">${this.loc.about}</p>
          <div></div>
        </div>
      </div>
    `;

    this.about = this.fullscreen.querySelector(`.${this.classes.about} div`);
    this.controls = this.fullscreen.querySelector(`.${this.classes.controls} div`);
    this.details = this.fullscreen.querySelector(`.${this.classes.details}`);
    this.stage = this.fullscreen.querySelector(`.${this.classes.stage}`);
    this.thumbnails = this.fullscreen.querySelector(`.${this.classes.thumbnails} div`);

    this.stage.addEventListener('mousedown', (e) => this.onStageInteract(e));
    this.stage.addEventListener('touchstart', (e) => this.onStageInteract(e));
    this.stage.addEventListener('wheel', (e) => this.onStageWheel(e));

    const infoButton = this.fullscreen.querySelector(`button.${this.classes.info}`);
    infoButton.addEventListener('click', (e) => this.onClickInfoButton(e));

    const closeButton = this.fullscreen.querySelector(`button.${this.classes.close}`);
    closeButton.addEventListener('click', () => this.destroy());

    this.thumbnailButtons = [];
    this.groups.forEach((group) => this.renderThumbnail(group));

    this.zoomSlider = this.fullscreen.querySelector(`.${this.classes.zoomSlider}`);
    this.zoomSlider.addEventListener('change', (e) => this.onZoomSliderChange(e));

    this.overlayCheckbox = this.fullscreen.querySelector(`.${this.classes.overlayCheckbox} input`);
    this.overlayCheckbox.addEventListener('change', (e) => this.onOverlayCheckboxChange(e));

    document.body.append(this.fullscreen);
    document.body.classList.add(this.classes.overflowHidden);
    this.calculateOrientation();
  }

  renderThumbnail(group) {
    const { thumbnail } = group.dataset;
    if (!thumbnail) return;
    const button = document.createElement('button');
    const image = document.createElement('img');
    image.src = thumbnail;
    image.width = this.thumbnailWidth;
    image.height = this.thumbnailHeight;
    button.append(image);
    button.addEventListener('click', (e) => this.onClickThumbnailButton(e, group));
    this.thumbnails.append(button);
    this.thumbnailButtons.push(button);
  }

  renderImage(figure) {
    if (!figure) return;
    const link = figure.querySelector('a');
    if (!link.hasAttribute('href')) return;

    const image = document.createElement('div');
    const spinner = document.createElement('span');
    spinner.classList = this.classes.spinner.join(' ');
    image.append(spinner);

    const preloadImage = document.createElement('img');
    preloadImage.src = link.href;
    preloadImage.addEventListener('load', () => {
      image.style.backgroundImage = `url(${link.href})`;
      spinner.remove();
      preloadImage.remove();
    });

    const caption = figure.querySelector('figcaption');
    const captionText = caption ? caption.innerHTML.trim() : '';
    if (captionText) {
      const label = document.createElement('span');
      label.classList.add(this.classes.label);
      label.innerHTML = captionText;
      image.append(label);
    }

    this.stage.append(image);
    this.stageImages.push(image);
  }

  renderAbout(group) {
    const target = this.about.parentElement;
    const about = group.querySelector(':scope > figcaption');
    const aboutMarkup = about ? about.innerHTML.trim() : '';
    if (aboutMarkup) {
      target.classList.remove(this.classes.hidden);
      this.about.innerHTML = aboutMarkup;
    } else {
      target.classList.add(this.classes.hidden);
      this.about.innerHTML = '';
    }
  }

  renderToggles(group) {
    this.controls.innerHTML = '';
    const figures = group.querySelectorAll(':scope > figure');
    this.renderToggleButton(this.loc.all, [].slice.call(figures), true);
    figures.forEach((figure) => {
      const caption = figure.querySelector(':scope > figcaption');
      if (caption && caption.innerHTML) {
        this.renderToggleButton(caption.innerHTML, [figure]);
      }
    });
  }

  renderToggleButton(label, figures, active = false) {
    const button = document.createElement('button');
    button.innerHTML = label;
    button.classList.add(...this.classes.button);
    if (active) button.classList.add(this.classes.active);
    button.addEventListener('click', (e) => this.onClickToggleButton(e, figures));
    this.controls.append(button);
  }

  update(group) {
    if (!this.fullscreen) return;
    const figures = group.querySelectorAll('figure');
    this.updateImages(figures);
    this.renderAbout(group);
    this.renderToggles(group);
  }

  updateImages(figures) {
    this.stage.innerHTML = '';
    this.stageImages = [];
    figures.forEach((figure) => this.renderImage(figure));
    this.drawImages();
  }

  launch(group) {
    this.destroy();
    this.resetPos();
    this.render();
    this.update(group);
    this.activateThumbnailButton(group);

    document.addEventListener('keydown', this.onKeydownHandler);
    document.addEventListener('mouseleave', this.onInteractEndHandler);
    document.addEventListener('mouseup', this.onInteractEndHandler);
    document.addEventListener('touchend', this.onInteractEndHandler);
    window.addEventListener('blur', this.onBlurHandler);
    window.addEventListener('resize', this.onResizeHandler);
  }

  destroy() {
    if (this.fullscreen) this.fullscreen.remove();

    document.body.classList.remove(this.classes.overflowHidden);

    document.removeEventListener('keydown', this.onKeydownHandler);
    document.removeEventListener('mouseleave', this.onInteractEndHandler);
    document.removeEventListener('mouseup', this.onInteractEndHandler);
    document.removeEventListener('touchend', this.onInteractEndHandler);
    window.removeEventListener('blur', this.onBlurHandler);
    window.removeEventListener('resize', this.onResizeHandler);

    this.overlay = false;
    this.zoomModifier = this.zoomStart;
  }
}

export default class BrockmanRecommendations {
  constructor(args) {
    this.placeholders = args.placeholders;
    this.articles = args.articles;
    this.loc = args.loc || {
      readMore: `Read more on`,
    };
  }

  run() {
    if (this.articles) {
      this.articles.forEach((article, index) => {
        this.generateRecommendation(article, this.placeholders.item(index));
      });
    }

    this.registerRecommendationClick();
  }

  generateRecommendation(article, placeholder) {
    if (
      article.url &&
      article.title &&
      article.publication &&
      article.thumbnail &&
      article.thumbnailWidth &&
      article.thumbnailHeight &&
      placeholder
    ) {
      const position = placeholder.dataset.position;
      const aside = document.createElement('aside');
      aside.classList = 'recommendation aside';
      aside.dataset.position = position;
      aside.innerHTML = `
        <div class="thumbnail">
          <img src="${article.thumbnail}" loading="lazy" alt="${article.title} image" width="${article.thumbnailWidth}" height="${article.thumbnailHeight}"/>
        </div>
        <div class="details">
          <p class="title">
            <a href="${article.url}" class="link link--expand link--invert" data-recommendation="true">${article.title}</a>
          </p>
          <a href="${article.url}" class="button small" data-recommendation="true">${this.loc.readMore} ${article.publication}</a>
        </div>
      `;
      placeholder.replaceWith(aside);
    }
  }

  registerRecommendationClick() {
    const recommendations = document.querySelectorAll('.recommendation');
    if (recommendations) {
      recommendations.forEach((recommendation) => {
        const url = recommendation.querySelector('.title a').href;
        const { position } = recommendation.dataset;
        const title = recommendation.querySelector('.title').innerText;
        if (url && position && title)
          recommendation
            .querySelectorAll('a')
            .forEach((a) => a.addEventListener('click', () => this.sendToPiwik(url, title, position)));
      });
    }
  }

  sendToPiwik(url, title, position) {
    window._paq = window._paq || [];
    window._paq.push([
      'trackEvent',
      'navigation', // Category
      'article recommendation click', // Action
      `${title}: ${url}`,
      parseInt(position, 10),
    ]);
  }
}

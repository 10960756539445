export default class BrockmanCompetition {
  constructor({ element, loc }) {
    this.element = element;
    this.endpoint = element.dataset.endpoint;
    this.loc = loc;
    this.canonical_url_relative = window.location.pathname + window.location.search;
  }

  run() {
    this.element.dataset.init = true;

    const url = new URL(this.endpoint, window.location.origin);
    url.searchParams.set('parent', this.canonical_url_relative);

    fetch(url).then((response) => this.status(response))
    .then((response) => {
      response.text().then((content) => {
        this.element.innerHTML = content;
        const form = this.element.querySelector('form');
        if (form) {
          form.addEventListener('submit', (event) => this.postForm(event));
        }
        const comp = this.element.querySelector('.competition');
        comp.style.minHeight = comp.clientHeight + 'px';
      });
    }).catch(() => this.renderAlert(this.loc.genericError));
  }

  postForm(event) {
    event.preventDefault();

    const form = event.target.closest('form');
    const formData = new FormData(form);
    const url = new URL(form.action, window.location.origin);

    if (!formData.get('answer')) {
      this.renderAlert(this.loc.noAnswerError, form);
      return
    }
    this.removeAlert();

    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    }).then((response) => this.status(response))
    .then((response) => {
      this.element.querySelector('h3').innerHTML = this.loc.successMessage;
      this.element.querySelectorAll('.description, form, .fine-print').forEach(el => el.remove());
    }).catch(() => this.renderAlert(this.loc.submitError, form));
  }

  renderAlert(message, where = null, type = 'error') {
    this.removeAlert();
    const div = document.createElement('div');
    div.innerHTML = message;
    div.classList = `alert ${type}`;
    if (!where) where = this.element;
    where.prepend(div);
  }

  removeAlert() {
    const alert = this.element.querySelector('.alert');
    if (alert) alert.remove();
  }

  status(response) {
    if (response.ok) {
      this.removeAlert();
      return response;
    }
    throw new Error('Response not OK');
  }
}
